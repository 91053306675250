import { userStore } from '@context/userStore';
import { getCurrentProfileBundles } from '@providers/profile';
import * as Sentry from '@sentry/browser';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

const DynamicSubscriptionComponent = ({
  defaultAltComponent,
  standardAltComponent,
  plusAltComponent,
  enterpriseAltComponent,
}) => {
  const [pageLoaded, setPageLoaded] = useState(false);
  const [userBundles, setUserBundles] = useState([]);
  const isLoggedIn = userStore((state) => state.isLoggedIn);
  const subscriptionUpgradeInProgress = userStore((state) => state.subscriptionUpgradeInProgress);

  useEffect(() => {
    fetchUserSubscription();
  }, [isLoggedIn]);

  const fetchUserSubscription = async () => {
    try {
      setPageLoaded(false);
      if (isLoggedIn) {
        if (subscriptionUpgradeInProgress) {
          setUserBundles([{ bundle: { name: 'Upgrade' } }]);
        } else {
          const response = await getCurrentProfileBundles();
          if (response && response.status === 200) {
            setUserBundles(response.data);
          }
        }
      } else {
        setUserBundles([]);
      }
      setPageLoaded(true);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  function subscriptionSwitch(userBundles) {
    let foundComponent = defaultAltComponent;

    for (const bundle of userBundles) {
      if (bundle.bundle.name) {
        switch (bundle.bundle.name) {
          case 'Upgrade':
            return plusAltComponent;
          case 'Enterprise Business':
            return enterpriseAltComponent;
          case 'Enterprise Education':
            if (foundComponent !== enterpriseAltComponent) {
              foundComponent = enterpriseAltComponent;
            }
            break;
          case 'Plus':
          case 'Partner':
          case 'Docent':
          case 'Cursist':
            if (foundComponent !== enterpriseAltComponent) {
              foundComponent = plusAltComponent;
            }
            break;
          case 'Standaard':
          case 'Proeflicentie':
            if (foundComponent === defaultAltComponent || foundComponent === standardAltComponent) {
              foundComponent = standardAltComponent;
            }
            break;
          case 'Gratis':
            if (foundComponent === defaultAltComponent) {
              foundComponent = defaultAltComponent;
            }
            break;
        }
      }
    }
    return foundComponent;
  }

  if (!pageLoaded) {
    return null;
  }

  const selectedComponent = subscriptionSwitch(userBundles);

  return selectedComponent;
};

DynamicSubscriptionComponent.propTypes = {
  defaultAltComponent: PropTypes.node,
  standardAltComponent: PropTypes.node,
  plusAltComponent: PropTypes.node,
  enterpriseAltComponent: PropTypes.node,
};

export default DynamicSubscriptionComponent;
